<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit">
      <div slot="info" v-if="paramsId">
        <div>
          <span class="mr-20">门店编号：S{{form.code}}</span>
          <span class="mr-20">授权有效期：{{form.authTime}}</span>
          <span class="mr-20">门店状态：{{form.statusName}}</span>
        </div>
        <span class="mr-20">所属门店：{{form.storeName}}</span>
        <span class="mr-20">创建人：{{form.createUserName}}</span>
        <span class="mr-20">创建时间：{{form.createTime}}</span>
      </div>
      <div slot="content">
        <a-card title="门店信息">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="门店名称" prop="name">
                  <a-input v-model="form.name" :maxLength="50" allowClear :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="门店负责人" prop="boss">
                  <a-select v-model="form.boss" allowClear :disabled="isDetail">
                    <a-select-option :value="1">
                      傻子
                    </a-select-option>
                    <a-select-option :value="2">
                      老大
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="电话" prop="phone">
                  <a-input v-model="form.phone" :maxLength="50" allowClear :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="地址" prop="address">
                  <a-input v-model="form.address" :maxLength="150" allowClear :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="备注">
                  <a-input v-model="form.remark" type="textarea" :auto-size="{ minRows: 4, maxRows: 6 }" allowClear :maxLength="100" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { storeInfo, storeSubmit } from '@/api/store';
import formMixin from '@/mixin/formMixin';

export default {
  name: 'ChargeRuleForm',
  mixins: [formMixin],
  components: {},
  props: {},
  data() {
    return {
      form: {
        name: '',
        boss: 1,
        phone: '',
        address: '',
        remark: '',
        editable: false,
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入门店名称',
            trigger: 'blur',
          },
          {
            min: 4,
            max: 50,
            message: '长度在4-50字之间',
            trigger: 'blur'
          }
        ],
        boss: [
          {
            required: true,
            message: '请选择门店负责人'
          }
        ],
      },
    };
  },
  computed: {
    paramsId() {
      return this.$route.params.id;
    }
  },
  watch: {},
  created() {
    if (this.paramsId) {
      this.getData();
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取信息
    getData() {
      storeInfo({ id: this.paramsId }).then((data) => {
        this.form = data;
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = this.form;

          storeSubmit(params).then((res) => {
            this.$message.success(res.message);
            this.handleBack();
          })
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
